<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-date-picker v-model="searchDate" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
      </div>
      <div class="search-box">
        帖子ID:
       <el-input type="text" class="width_80" placeholder="请输入帖子ID" v-model="search.book_id"></el-input>
      </div>
      <div class="search-box">
        用户ID:
        <el-input type="text" class="width_80" placeholder="请输入用户ID"  v-model="search.discuss_uid"></el-input>
      </div>
      <div class="search-box">
        关键字:
        <el-input type="text" class="width_100" placeholder="请输入关键字" v-model="search.content"></el-input>
      </div>
      <div class="search_box">
        状态:<el-select class="width_80" v-model="search.enable">
        <el-option  :value=-1 label="全部"></el-option>
        <el-option  :value=0 label="关闭"></el-option>
        <el-option  :value=1 label="正常"></el-option>
      </el-select>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
        <el-button type="primary" size="medium" @click="enableSwitchMulti(0)">批量屏蔽</el-button>
        <el-button type="primary" size="medium" @click="enableSwitchMulti(1)">取消屏蔽</el-button>
        <el-button type="primary" size="medium" @click="showSilentList()">禁言管理</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selection-change="selectionChange" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="avatar" label="用户头像" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="用户" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="book_id" label="分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.book_id <= 20000000">帖子</span>
          <span v-if="scope.row.book_id > 20000000 && scope.row.book_id <= 40000000">漫画</span>
          <span v-if="scope.row.book_id > 40000000 && scope.row.book_id <= 80000000">美图</span>
          <span v-if="scope.row.book_id > 80000000">小说</span>:
          <span>{{scope.row.book_id}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="评论内容" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="goods_count" label="点赞次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value=1 :inactive-value=0 @click="enableSwitch(scope.row)" :value="scope.row.enable" ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.is_silent == 0" @click="showDialog(scope.row)">禁言</span>
          <span class="operation" v-if="scope.row.is_silent == 1" @click="DelSilentOnList(scope.row)">解禁</span>
          <span class="operation"  v-if="scope.row.is_lock == 0" @click="lahei(scope.row,1)" >冻结</span>
          <span class="operation"  v-if="scope.row.is_lock == 1" @click="lahei(scope.row,0)" >解冻</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog :title="silentTitle" v-model="silentVisible" width="600px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">禁言时间</el-col>
        <el-col :span="20">
          <el-select v-model="silentDialog.silent_day_selection" @change="silentDialog.silent_day = silentDialog.silent_day_selection">
            <el-option v-for="item of silentTimeOptions" :key="item" :value="item.value" :label="item.label"></el-option>
          </el-select>
          <el-input v-model="silentDialog.silent_day" placeholder="禁言时间"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">备注</el-col>
        <el-col :span="20">
          <el-input v-model="silentDialog.reason" placeholder="请输入禁言备注"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right"></el-col>
        <el-col :span="18">
          <el-button @click="silentVisible=false">关闭</el-button>
          <el-button @click="saveSilent()">禁言</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>

    <el-dialog :title="silentTitle" v-model="silentListVisible" width="1000px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="24">
          <div class="search_box">
            <el-input class="width_200" placeholder="请输入账号" v-model="searchSilentList.username"></el-input>
            <el-button @click="getSilentList()">查询</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table :data="silentListTable" :header-cell-style="{background:'#F7F8FA'}">
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column prop="account" label="用户" align="center"></el-table-column>
            <el-table-column prop="add_time" label="禁言时间" align="center" ></el-table-column>
            <el-table-column prop="expire_time" label="解禁时间" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <span class="operation" @click="DelSilent(scope.$index,scope.row)" >删除</span>
              </template>
            </el-table-column>
          </el-table>
          <page :pager="silentListPager" @query="getSilentList()" />
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "community_discuss",
  components: {
    page,
  },
  data(){
    return {
      searchDate:[],
      search:{
        type:-1,
        book_id:"",
        discuss_uid:"",
        content:"",
        checked:0,
        enable:-1,
        begin_time:"",
        end_time:"",
      },
      silentTimeOptions:[
        {value:60,label:"1分钟"},
        {value:60*5,label:"5分钟"},
        {value:60*15,label:"15分钟"},
        {value:60*30,label:"半小时"},
        {value:3600,label:"1小时"},
        {value:86400,label:"1天"},
        {value:86400*30,label:"30天"},
        {value:86400*365,label:"365天"},
        {value:0,label:"永久"},
      ],
      pager: { total: 0, page: 1, rows: 50 },
      tableData:[
      ],
      selectMultiRows:[],
      silentVisible:false,
      silentTitle:"禁言",
      silentDialog:{
        row:null,
        silent_day_selection:60,
        silent_day:60,
        reason:"",
        uids:[],
      },
      silentListVisible:false,
      silentListTable:[
        // {uid:1,account:"牛逼",expire_time:"100年",add_time:"",remark:"太嚣张了"},
      ],
      searchSilentList:{
        username:"",
      },
      silentListPager:{
        total: 0, page: 1, rows: 50
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    lahei(row,status){
      // 调用户的解冻/冻结接口
      httpClient("updateStatus").post({user_id:row.uid}).then((res)=>{
        if (res.code == 0){
          row.is_lock = status;
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    },
    getSilentList(){
      this.$common.showLoading()
      httpClient("GetSilentList").post({
        row:this.silentListPager.rows,
        page:this.silentListPager.page,
        account:this.searchSilentList.username,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.silentListTable = res.data.list
          if (this.silentListPager.page == 1){
            this.silentListPager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    showSilentList(){
      this.silentListVisible = true
      this.getSilentList()
    },
    DelSilent(index,row){
      this.$common.showLoading()
      httpClient("DelSilentUid").post({
        user_ids:row.uid,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.silentListTable.splice(index,1)
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    DelSilentOnList(row){
      this.$common.showLoading()
      httpClient("DelSilentUid").post({
        user_ids:row.uid,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          row.is_silent = 0
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    saveSilent(){
      if (this.silentDialog.uids.length == 0){
        this.$message({message:"请选择要禁言的用户!",type:"error"})
        return
      }
      this.$common.showLoading()
      httpClient("AddSilentUid").post({
        user_ids:this.silentDialog.uids.join(","),
        silent_time:this.silentDialog.silent_day,
        remark:this.silentDialog.reason,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.silentVisible = false
          if (this.silentDialog.row){
            this.silentDialog.row.is_silent = 1
            this.silentDialog.row = null
          }
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    // AddSilentUid
    showDialog(row){
      this.silentVisible = true
      this.silentTitle = "禁言ID:"+row.id
      this.silentDialog = {
          row:row,
          silent_day_selection:60,
          silent_day:60,
          reason:"",
          uids:[row.id],
      }
    },
    selectionChange(rows){
      this.selectMultiRows = rows;
    },
    enableSwitchMulti(enable){
      if (this.selectMultiRows.length == 0){
        this.$message({message:"请先选择评论!",type:"error"})
        return;
      }
      var ids = [];
      for(const row of this.selectMultiRows){
        ids.push(row.id)
      }
      this.$common.showLoading()
      httpClient("BatchStatus").post({ids:ids.join(","),enable:enable}).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          for(const row of this.selectMultiRows){
            row.enable = enable
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    enableSwitch(row){
      this.$common.showLoading()
      httpClient("BatchStatus").post({ids:row.id,enable:row.enable==0?1:0}).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          row.enable = row.enable == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    getList(){
      var params = {
        page:this.pager.page,
        row:this.pager.rows,
        book_id:this.search.book_id,
        discuss_uid:this.search.discuss_uid,
        content:this.search.content,
        checked:this.search.checked,
        enable:this.search.enable,
        begin_time:this.searchDate && this.searchDate.length > 1?this.searchDate[0]:"",
        end_time:this.searchDate && this.searchDate.length > 1?this.searchDate[1]:"",
      }
      this.$common.showLoading()
      httpClient("GetDiscussList").post(params).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.tableData = res.data.list
          if (this.pager.page == 1 ){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>